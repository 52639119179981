.App {
	text-align: center;
  }
  
  .App-logo {
	height: 40vmin;
	pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
	.App-logo {
	  animation: App-logo-spin infinite 20s linear;
	}
  }

  .btn-daytime{
	background-color: #85aad2  !important;
	color:black;
	opacity:90%;
	margin-right:5px;
	height:30px;
  }
  
  .btn-daytime:hover{
	color:black;
	opacity:100%;
  }
  
  .btn-nighttime{
	background-color: #5665b6  !important;
	color:black;
	opacity:90%;
	margin-right:5px;
	height:30px;
  }
  
  .btn-nighttime:hover{
	color:black;
	opacity:100%;
  }
  

  .bg-soon {
	color:black;
	background-color:#e9ca7a !important;
  }

  .btn-secondary {
	color: white !important; 
  }

  .xmint-btn{
	margin: 0 auto !important;
	margin-top:10px !important;
	margin-bottom:10px !important;
	font-size:10px !important;
}

  button.xmint-btn span {
	display: none;
}
	button.xmint-btn:after {
	content: "Mint with Credit Card";
	color: white; 
	}

	div::-webkit-scrollbar {
		width: 8px;
	  }
	  
	  div::-webkit-scrollbar-track {
		background-color: #f1f1f1;
	  }
	  
	  div::-webkit-scrollbar-thumb {
		background-color: #4c82f7;
		border-radius: 4px;
	  }
	  
	  div::-webkit-scrollbar-thumb:hover {
		background-color: #6366f1;
	  }

	
 .active-button{
	border: 2px solid white
 }

	.active-tickets {
		color: #e9ca7a !important;
	}

	.tickets {
		cursor:pointer !important
	}


  .form-check-input:checked {
	background-color:#e9ca7a !important;
  }

  .form-check-input[type=checkbox]:indeterminate {
	background-color:#e9ca7a !important;
  }

  .form-check-input {
	background-color:#e9ca7a !important;
  }
  
  .btn-primary {
	color:black !important;
	background-color:#e9ca7a !important;
	border:none !important;
  }
  
  .btn-primary:hover {
	color:white !important;
	background-color:#e9ca7a !important;
	border:none !important;
  }
  
  
  .App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
  }
  .form-check-input{
	cursor:pointer !important;
  }

  

  
  .mobile-filter{
	background-color:#20223b !important;
  }
  
  .sticky {
	  position: -webkit-sticky;
	  position: sticky;
	  top: 0;
	  z-index: 1 !important;
  }
  
  .App-link {
	color: #61dafb;
  }
  
  @keyframes App-logo-spin {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }
  
  .disabled {
	color: black !important;
	opacity: .5;
  }

  .disabled-button {
	pointer-events: none !important;  
	opacity: 0.5 !important;
  }

  .disabled-nav {
	color: white !important;
	opacity: .5;
  }
  
  .mint-page-day {
	  overflow:hidden !important;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  text-align: center;
	  min-height: 89vh;
	background: url(day.png) no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
  }
  
  .mint-page-night {
	overflow:hidden !important;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  text-align: center;
	  min-height: 89vh;
	  background: url(night.png) no-repeat center center fixed; 
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
  }
  
  .mint-module {
	background: rgba(23, 23, 28, 0.75);
  border-radius: 100px;
  }
  
  .mint-image {
	justify-content: center;
	  align-items: center;
	height:125px !important;
	width:125px !important;
	padding:10px;
	margin-top:20px;
	background: black;
	border-radius: 50%;
  }
  
  
	.mint-text-small {
	  font-style: normal;
	font-weight: 400;
	font-size: 16px;
	text-decoration:none;
	color: #FFFFFF;
	}
  
  .mint-text-large {
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	text-decoration:none;
  color: #FFFFFF;
  }
  
  .btn-war:hover{
	background-color: black !important;
  }
  
  .btn-day:hover{
	background-color: lightgreen !important;
  }
  
  .input-section {
	margin: 0 auto;
	padding:18px;
  }

  .connect-section {
	margin: 0 auto;
  }

  .connect-section {
	text-align: center;
  }
  
  /* If the text has multiple lines, add the following: */
  .connect-section div {
	line-height: 1.5;
	display: inline-block;
	vertical-align: middle;
  }

  .text-loading {
	clip-path: inset(0 1.1ch 0 0);
	animation: text-load-animation 1s steps(4) infinite;
  }
  
  @keyframes text-load-animation {
	to {
	  clip-path: inset(0 -0.5ch 0 0);
	}
}